<template>
  <VueEditor
    v-model="paragraph.content"
    placeholder="Digite o texto aqui"
    :editor-toolbar="customToolbar"
    @blur="onBlurUpdateContent"
  ></VueEditor>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "DocumentationTextParagraph",
  props: {
    paragraph: Object,
    documentationId: String,
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block", "clean"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],

        [
          {
            color: [],
          },
          {
            background: [],
          },
        ],

        ["link", "image", "video"],
      ],
    };
  },
  mounted() {},
  methods: {
    onBlurUpdateContent() {
      this.$emit("startsLoading");
      this.$http
        .post("/documentation-paragraph/update", {
          id: this.paragraph.id,
          user_id: this.$store.state.userData.id,
          documentation_id: this.documentationId,
          type: this.paragraph.type,
          content: this.paragraph.content,
          order: this.paragraph.order,
        })
        .then(() => {
          this.$emit("savedSuccessfully");
        });
    },
  },
};
</script>

<style lang="sass" scoped>
iframe.ql-video
  max-width: 640px
  width: 100%
  margin: 0 auto
  height: 480px
  display: block
</style>
